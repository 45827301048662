import { useCallback } from 'react';

export const useFetchGlucoseData = (authorizedHttpClient, selectedUserId, isAdmin) => {
    console.log("selectedUserId", selectedUserId); // Logs the user ID passed to the hook

    const fetchGlucoseData = useCallback(async (fromDate, tillDate, targetUserId = null) => {
        try {
            const params = new URLSearchParams({
                from: fromDate.toISOString(),
                till: tillDate.toISOString(),
                include: 'glucose',
            });

            let endpoint;

            // Use isAdmin to determine the endpoint
            if (isAdmin && targetUserId) {
                // If the user is an admin and a targetUserId is provided, fetch for that specific user
                endpoint = `/api/meals/timeline/${targetUserId}?${params.toString()}`;
                console.log("Admin endpoint:", endpoint);
            } else {
                // Otherwise, fetch for the current user (using the userId passed to the hook)
                endpoint = `/api/meals/timeline/${selectedUserId}?${params.toString()}`;
                console.log("User endpoint:", endpoint);
            }

            const response = await authorizedHttpClient.jsonRequest('GET', endpoint);
            console.log("response", response);
            return response;
        } catch (error) {
            console.error('Error fetching glucose data', error);
            return null; // Handle errors gracefully
        }
    }, [authorizedHttpClient, isAdmin, selectedUserId]);

    return fetchGlucoseData;
};
