import React, {useCallback, useEffect, useMemo, useState} from "react";

import {
    Typography,
    Box,
    Paper, Button,
} from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';

import dayjs from "dayjs";
import {useServices} from "../providers/service-provider";
import Lottie from 'react-lottie';
import CustomMenu from "../Menu";
import MealsWithGraph from "../ListView/MealsWithGraph";
import DayDataView from "./DayDataView";
import * as food_carousel from '../images/food_carousel.json'
import Maps3 from "../Maps/Maps3";
import {calculateTimeInRange} from "../utils/timeInRange";


const DataAnalyizer = (props) => {
    const {user, isAdmin} = props
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null); // Add error state
    const {authorizedHttpClient} = useServices();
    const [yAxisMax, setYAxisMax] = useState(180);
    const [selectedUserId, setSelectedUserId] = useState(user);
    const [selectedUserName, setSelectedUserName] = useState('Select User');
    const [showListView, setShowListView] = useState(true);
    const itemsPerLoad = 2; // Number of items to load each time
    const [visibleItemCount, setVisibleItemCount] = useState(itemsPerLoad);
    const [loadAllDays, setLoadAllDays] = useState(false)
    const [showMapOverlay, setShowMapOverlay] = useState(false);
    const [avgTIRByLocation, setAvgTIRByLocation] = useState({});

    const high = 140

    const [hasMoreData, setHasMoreData] = useState(true); // Initialize to true

    const fetchMeals = useCallback(async (isInitialLoad = false) => {
        setIsLoading(true);
        setError(null);

        if (isInitialLoad) {
            setData([]);  // Reset data on initial load
        }

        console.log("beforeload Check all days", loadAllDays)
        let url = `/users/${selectedUserId}/meals?loadAllDays=${loadAllDays}`;
        if (startDate && endDate) {
            const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
            const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
            url += `&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        }


        try {
            const response = await authorizedHttpClient.jsonRequest('GET', url);
            if (Array.isArray(response.data) && response.data.length > 0) {
                // Augment each meal with TIR
                const dataWithTIR = response.data.map(day => ({
                    ...day,
                    meals: day.meals.map(meal => {
                        const mealTime = new Date(meal.eatenAt);
                        const endTime = new Date(mealTime.getTime() + (3 * 60 * 60 * 1000)); // 3 hours later
                        const TIR = calculateTimeInRange(day.timelineEvents, mealTime, endTime); // Assumes timelineEvents are accessible here
                        return {...meal, TIR}; // Augment the meal object with the TIR value
                    })
                }));

                // Example aggregation of TIR by location
                const tirByLocation = {}; // Object to hold location ID keys and arrays of TIR values

                dataWithTIR.forEach(day => {
                    day.meals.forEach(meal => {
                        if (meal.preparationPlace && meal.preparationPlace.location) {
                            const locationId = meal.preparationPlace.id;
                            if (!tirByLocation[locationId]) {
                                tirByLocation[locationId] = [];
                            }
                            if (meal.TIR !== undefined) {
                                tirByLocation[locationId].push(meal.TIR);
                            }
                        }
                    });
                });

                // Calculate average TIR for each location
                const avgTIRByLocation = {};
                Object.keys(tirByLocation).forEach(locationId => {
                    const tirValues = tirByLocation[locationId];
                    const avgTIR = tirValues.reduce((acc, curr) => acc + curr, 0) / tirValues.length;
                    avgTIRByLocation[locationId] = avgTIR;
                });
                setAvgTIRByLocation(avgTIRByLocation);


                if (isInitialLoad) {
                    setData(dataWithTIR);
                } else {
                    setData(prevData => [...(prevData || []), ...dataWithTIR]);
                }
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    }, [startDate, endDate, selectedUserId, authorizedHttpClient, loadAllDays]);

    useEffect(() => {
        fetchMeals(false); // This will be triggered when startDate or endDate changes
    }, [loadAllDays]);

    useEffect(() => {
        fetchMeals(true); // This will be triggered when startDate or endDate changes
    }, [startDate, endDate, fetchMeals]);

    const loadMoreItems = () => {
        if (visibleItemCount < data.length) {
            setVisibleItemCount(prevCount => {
                return prevCount + itemsPerLoad;
            });
        }
    };

    useEffect(() => {
        const moreDataAvailable = visibleItemCount < data.length;
        setHasMoreData(moreDataAvailable);
    }, [visibleItemCount, data.length]);


    // Function to extract locations from your data for the map
    const extractAndAugmentLocationsForMap = (data) => {
        // Use the previously shown logic to calculate avgTIRByLocation
        // Then, augment the location data with this TIR information
        const locations = data.reduce((acc, day) => {
            day.meals.forEach(meal => {
                if (meal.preparationPlace && meal.preparationPlace.location) {
                    const {location, id, name} = meal.preparationPlace;
                    const hasValidLocation = location && !isNaN(location.lat) && !isNaN(location.lng);

                    // Find if this location is already added
                    const existingLocation = acc.find(loc => loc.id === id);
                    if (!existingLocation) {
                        acc.push({
                            ...location,
                            name: name,
                            id: id,
                            avgTIR: avgTIRByLocation[id] ? avgTIRByLocation[id].toFixed(2) : "N/A" // Add avg TIR to location
                        });
                    }
                }
            });
            return acc;
        }, []);

        return locations;
    };


    const getLatestDate = async () => {
        try {
            const response = await authorizedHttpClient.jsonRequest('GET', `/users/${selectedUserId}/meals/latest-meal-date`);
            console.log("Response received:", response);
            if (response.data) {
                const latestDate = dayjs(response.data);
                setEndDate(latestDate);
                // Set start date to a period before the latest date (e.g., 1 month)
                setStartDate(latestDate.subtract(1, 'month'));
                console.log(response.data)
            }
        } catch (error) {
            console.error("Error fetching latest meal date:", error);
        }
    }

    useEffect(() => {
        getLatestDate();
    }, [selectedUserId]); // Dependency array includes selectedUserId to refetch when it changes


    const visibleData = useMemo(() => {
        return data.slice(0, visibleItemCount);
    }, [data, visibleItemCount]);

    const handleToggleView = () => {
        setShowListView(!showListView);
    };
    const handleUserSelect = (userId, userName) => {
        setSelectedUserId(userId);
        setSelectedUserName(userName);
    };

    if (error) {
        return <div>Error: {error}</div>; // Display the error message
    }

    if (isLoading || data === null) {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: food_carousel,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return <Box>
            <Lottie options={defaultOptions}
                    height={400}
                    width={400}
            /><br/>
            Hold on - you have a lot of data
            <Box className="loader"></Box>
        </Box>;
    }

    return (

        <div className="App">
            <CustomMenu
                data={data}
                loadAllDays={loadAllDays}
                setLoadAllDays={setLoadAllDays}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setYAxisMax={setYAxisMax}
                isAdmin={isAdmin}
                selectedUserName={selectedUserName}
                handleUserSelect={handleUserSelect}
                user={user}
                selectedUserId={selectedUserId}
                handleToggleView={handleToggleView}
                showListView={showListView}
                setShowMapOverlay={setShowMapOverlay}
                showMapOverlay={showMapOverlay}
            />


            {showMapOverlay && (
                <Maps3
                    data={extractAndAugmentLocationsForMap(data)}
                    onClose={() => setShowMapOverlay(false)}
                />
            )}


            {data && data.length > 0 ? <div><h1 style={{color: '#fff'}}>Glukose Report</h1></div> :
                <Box sx={{margin: 2}}><Typography sx={{color: '#fff'}}>No Data for the selected Date</Typography></Box>}

            <InfiniteScroll
                dataLength={visibleData.length}
                next={loadMoreItems}
                hasMore={hasMoreData}
                loader={<Box sx={{height: 300, backgroundColor: "red"}}><h4>Loading more...</h4></Box>}
                endMessage={<p style={{textAlign: 'center'}}>You have seen it all</p>}
                scrollThreshold={0.9} // Optionally set a scroll threshold

            >

                {visibleData.map((dayData) => {

                    const meals = dayData.meals;
                    const chartData = Array.from({length: 24 * 60 / 5}, (_, index) => ({
                        date: new Date(dayData.date).setHours(Math.floor(index / 12), (index % 12) * 5, 0, 0),
                        mealValue: undefined,
                        mealValueDetails: undefined,
                        timelineValue: undefined,
                    }));
                    // console.log("chartData", chartData)

                    for (const meal of meals) {

                        const eatenAt = new Date(meal.eatenAt);
                        const eatenAtIndex = eatenAt.getHours() * 12 + Math.floor(eatenAt.getMinutes() / 5);
                        chartData[eatenAtIndex].mealValue = 50; // Modify the y value as per your requirements
                        chartData[eatenAtIndex].mealValueDetails = {
                            name: meal.dishes.map(dish => dish.name).join(', '),
                            preparationPlaces: meal.dishes[0].preparationPlaces,
                            image: meal.images && meal.images.length > 0 ? meal.images[0].url : null,
                        };
                    }

                    const timelineEvents = dayData.timelineEvents;
                    for (const event of timelineEvents) {
                        const eventTime = new Date(event.date);
                        const eventIndex = eventTime.getHours() * 12 + Math.floor(eventTime.getMinutes() / 5);
                        chartData[eventIndex].timelineValue = event.payload.value;
                    }

                    const symptoms = dayData.symptoms;
                    const activities = dayData.activities;

                    for (const symptom of symptoms) {
                        const symptomTime = new Date(symptom.startDate);
                        const symptomIndex = symptomTime.getHours() * 12 + Math.floor(symptomTime.getMinutes() / 5);
                        chartData[symptomIndex].symptomValue = symptom.intensity ? symptom.intensity * 3 : 1; // or any other relevant value
                        chartData[symptomIndex].symptomDetails = symptom;
                    }

                    for (const activity of activities) {
                        const activityTime = new Date(activity.startDate);
                        const activityIndex = activityTime.getHours() * 12 + Math.floor(activityTime.getMinutes() / 5);
                        chartData[activityIndex].activityValue = activity.intensity ? activity.intensity * 3 : 1; // or any other relevant value
                        chartData[activityIndex].activityDetails = activity;
                    }


                    return (
                        <Paper sx={{marginBottom: 4, borderRadius: 2, margin: 3}} key={dayData.date}>
                            {showListView ? (
                                <DayDataView
                                    key={dayData.date}
                                    date={dayData.date}
                                    chartData={chartData}
                                    yAxisMax={yAxisMax}
                                    high={high}
                                    meals={dayData.meals}
                                />
                            ) : (
                                <MealsWithGraph
                                    key={dayData.date}
                                    meals={dayData.meals}
                                    timelineEvents={dayData.timelineEvents}
                                    symptoms={dayData.symptoms}
                                    activities={dayData.activities}
                                    high={high}
                                    yAxisMax={yAxisMax}
                                />
                            )}

                        </Paper>
                    );
                })}
                <Box sx={{padding: 4}}></Box>
            </InfiniteScroll>
            {visibleItemCount < data.length && (
                <Box sx={{textAlign: 'center', marginY: 2}}>
                    <Button variant="contained" onClick={() => loadMoreItems}>
                        Load More
                    </Button>
                </Box>
            )}


        </div>
    );
}

export default DataAnalyizer;
