import React from 'react';
import {Box, Typography, TableContainer, Paper, Table, TableCell, TableRow, TableBody, TableHead} from '@mui/material';
import {format} from 'date-fns';

const AverageSoberGlucoseTable = ({averageSoberGlucose}) => {
    // Calculate the sum and total average of average glucose values
    const sumOfAverages = averageSoberGlucose.reduce((sum, row) => sum + parseFloat(row.average), 0);
    const totalAverageGlucose = (sumOfAverages / averageSoberGlucose.length).toFixed(2);

    return (
        <Box className="exclude-from-capture" marginTop={4} marginY={4}>
            <Typography variant="h6">Average Sober Glucose </Typography>
            <Typography variant="body1">(2:00 - 6:00 AM) </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="right">Average Glucose</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {averageSoberGlucose.map((row) => (
                            <TableRow key={row.date}>
                                <TableCell component="th" scope="row">
                                    {row.date ? format(new Date(row.date), 'MMMM d, yyyy') : 'Invalid Date'}
                                </TableCell>
                                <TableCell align="right">{row.average}</TableCell>
                            </TableRow>
                        ))}
                        {/* Add a row for the total average */}
                        <TableRow>
                            <TableCell component="th" scope="row"
                                       style={{fontWeight: 'bold', backgroundColor: '#f0f0f0'}}>
                                Total Average
                            </TableCell>
                            <TableCell align="right" style={{fontWeight: 'bold', backgroundColor: '#f0f0f0'}}>
                                {totalAverageGlucose}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Information
                            </TableCell>
                            <TableCell align="right">
                                <a href={'https://www.leitlinien.de/themen/diabetes/pdf/diabetes-vers3-0.pdf'}
                                   title={"Nationale Versorgungsleitlinie Typ-2-Diabetes"}>Nationale
                                    Versorgungsleitlinie Typ-2-Diabetes</a>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AverageSoberGlucoseTable;
