import React, {useState} from 'react';
import {Button, Menu, MenuItem} from '@mui/material';
import {AnalyticsRounded} from "@mui/icons-material";

function YAxisSelect({setYAxisMax}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const glucoseValues = [160, 180, 200, 220, 240, 260, 300, 350, 400];
    const [selectedValue, setSelectedValue] = useState(180);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (value) => {
        setYAxisMax(value);
        setSelectedValue(value);

        handleClose();
    };

    return (
        <div>

            <Button startIcon={<AnalyticsRounded/>} sx={{ margin: 1 }} onClick={handleClick}>
                Y-Axis: {selectedValue}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {glucoseValues.map((value) => (
                    <MenuItem key={value} onClick={() => handleSelect(value)}>
                        {value}
                    </MenuItem>
                ))}
            </Menu>

        </div>
    );
}

export default YAxisSelect;
