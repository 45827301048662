import dayjs from 'dayjs';

export const analyzeTrendsByDayOfWeek = (jsonData) => {
    const glucoseDataByDay = {};
    const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    if (jsonData) {
        for (const date in jsonData) {
            if (jsonData.hasOwnProperty(date)) {
                // Ensure the date string is valid
                if (dayjs(date).isValid()) {
                    const dayData = jsonData[date];
                    const dayOfWeek = dayjs(date).format('dddd'); // Get day of the week

                    // Initialize day data if not already present
                    if (!glucoseDataByDay[dayOfWeek]) {
                        glucoseDataByDay[dayOfWeek] = {
                            totalGlucose30Min: 0,
                            totalGlucose2H: 0,
                            count: 0
                        };
                    }

                    // Assuming each day's data includes a meal entry with post-meal glucose values
                    const glucose30MinAfter = Number(dayData['Glukose 30 Min. nach Mahlzeit']);
                    const glucose2HAfter = Number(dayData['Glukose 2 Std. nach Mahlzeit']);

                    if (!isNaN(glucose30MinAfter) && !isNaN(glucose2HAfter)) {
                        glucoseDataByDay[dayOfWeek].totalGlucose30Min += glucose30MinAfter;
                        glucoseDataByDay[dayOfWeek].totalGlucose2H += glucose2HAfter;
                        glucoseDataByDay[dayOfWeek].count++;
                    }
                }
            }
        }

        // Calculate and sort average glucose level for each day of the week
        const averageGlucoseByDay = {};
        dayOrder.forEach(day => {
            if (glucoseDataByDay[day] && glucoseDataByDay[day].count > 0) {
                averageGlucoseByDay[day] = {
                    avgGlucose30MinAfter: glucoseDataByDay[day].totalGlucose30Min / glucoseDataByDay[day].count,
                    avgGlucose2HAfter: glucoseDataByDay[day].totalGlucose2H / glucoseDataByDay[day].count
                };
            } else {
                averageGlucoseByDay[day] = { avgGlucose30MinAfter: NaN, avgGlucose2HAfter: NaN }; // Or some default value if no data
            }
        });

        return averageGlucoseByDay;
    }
};

// Use this function with your JSON data
// const trendsByDay = analyzeTrendsByDayOfWeek(jsonData);
