import React, { useState } from "react";
import { Card, CardContent, CardMedia, Typography, Button, Modal, Alert, Box } from "@mui/material";
import { useServices } from "../providers/service-provider";
import { useFetchUserMealNutritionData } from "../api";
import NutritionLabel from "./NutritionLabel";
import {InfoRounded} from "@mui/icons-material";

export const calculateDailyValue = (amount, guideline) => {
    return ((amount / guideline) * 100).toFixed(0);
};

const MealCard = ({ meal, index, width, height }) => {
    const { authorizedHttpClient } = useServices();
    const fetchUserMealNutritionData = useFetchUserMealNutritionData(authorizedHttpClient);
    const [nutritionData, setNutritionData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [missingDataWarning, setMissingDataWarning] = useState(false);

    const handleFetchAndShowData = async () => {
        const data = await fetchUserMealNutritionData(meal.id); // Assuming meal.id is the user_meal_id
        setNutritionData(data);
            console.log(data)
        const hasMissingData = data.some(item =>
            item.fatG === null || item.saltG === null || item.sugarG === null ||
            item.carbohydratesG === null || item.proteinG === null || item.kilojoules === null ||
            item.fatSaturatedG === null // Check for Saturated Fat
        );

        setMissingDataWarning(hasMissingData);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setNutritionData(null);
        setMissingDataWarning(false);
    };

    const calculateTotals = (data) => {
        const totals = {
            fat: 0,
            salt: 0,
            sugar: 0,
            carbs: 0,
            protein: 0,
            kilojoules: 0,
            saturatedFat: 0,
            calories: 0, // Include Calories (kCal)
            portionSize: 0, // Sum of all portion sizes
        };

        data.forEach((item) => {
            const portionFactor = item.portionSize / 100; // Since the data is per 100g
            totals.fat += parseFloat(item.fatG || 0) * portionFactor;
            totals.salt += parseFloat(item.saltG || 0) * portionFactor;
            totals.sugar += parseFloat(item.sugarG || 0) * portionFactor;
            totals.carbs += parseFloat(item.carbohydratesG || 0) * portionFactor;
            totals.protein += parseFloat(item.proteinG || 0) * portionFactor;
            totals.kilojoules += parseFloat(item.kilojoules || 0) * portionFactor;
            totals.saturatedFat += parseFloat(item.fatSaturatedG || 0) * portionFactor;
            totals.calories += parseFloat(item.kilojoules || 0) * 0.239006 * portionFactor; // Convert kJ to kCal
            totals.portionSize += parseFloat(item.portionSize || 0); // Sum portion sizes
        });

        return totals;
    };

    const totals = nutritionData ? calculateTotals(nutritionData) : null;

    return (
        <>
            <Card key={index} sx={{ flexShrink: 0, display: 'flex', flexDirection: 'column' }}>
                {meal.images && meal.images.length > 0 ?
                    <CardMedia
                        component="img"
                        height={height}
                        sx={{ width: width }}
                        image={meal.images[0].url}
                        alt={`Meal ${index}`}
                    /> : <Box sx={{ height: height, width: width, backgroundColor: 'rgba(30,42,147,0.47)' }} />
                }
                <CardContent sx={{ width: width - 40 }}>
                    <Typography variant="body1" sx={{ fontSize: 16, fontWeight: "bold" }} component="div">
                        {meal.dishes && meal.dishes.map(dish => dish.name).join(', ')}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                        {meal.preparationPlace && meal.preparationPlace.name ? `Place : ${meal.preparationPlace.name} ` : null}
                    </Typography>
               {/*     <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                        {meal.id ? `ID : ${meal.id} ` : null}
                    </Typography>*/}
                    <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                        {meal.note ? `Note: ${meal.note}` : null}
                    </Typography>
                    {meal.TIR !== null && (
                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                            Time in Range: {meal.TIR.toFixed(2)}%
                        </Typography>
                    )}
                    {totals && (
                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                            Total Portion Size: {totals.portionSize.toFixed(2)}g
                        </Typography>
                    )}
                    <Button
                        startIcon={<InfoRounded />}
                        sx={{ marginTop: 2 }} size={"small"} variant="contained" color="secondary"
                        onClick={handleFetchAndShowData}>
                    </Button>
                </CardContent>
            </Card>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="nutrition-data-modal"
                aria-describedby="nutrition-data-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    maxHeight: '80vh', // Limit the height for scrollability
                    overflowY: 'auto', // Enable vertical scrolling
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '16px', // Add rounded corners
                }}>
                    <Typography id="nutrition-data-modal" variant="h6" component="h2">
                        Nutrition Data
                    </Typography>
                    {missingDataWarning && (
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            Some dishes are missing nutritional data, so totals may be inaccurate.
                        </Alert>
                    )}
                    {totals && (
                        <Box id="nutrition-data-modal-description" sx={{ mt: 2 }}>
                            <NutritionLabel
                                data={{
                                    name: "Total for All Dishes",
                                    servingsPerContainer: '1',
                                    servingSize: `${totals.portionSize.toFixed(2)}g`,
                                    calories: totals.calories.toFixed(2),
                                    nutrients: [
                                        {
                                            label: 'Fat',
                                            amount: `${totals.fat.toFixed(2)}g`,
                                            dailyValue: calculateDailyValue(totals.fat, 78)
                                        },
                                        {
                                            label: 'Saturated Fat',
                                            amount: `${totals.saturatedFat.toFixed(2)}g`,
                                            dailyValue: calculateDailyValue(totals.saturatedFat, 20)
                                        },
                                        {
                                            label: 'Carbohydrate',
                                            amount: `${totals.carbs.toFixed(2)}g`,
                                            dailyValue: calculateDailyValue(totals.carbs, 275)
                                        },
                                        {
                                            label: 'Sugars',
                                            amount: `${totals.sugar.toFixed(2)}g`,
                                            dailyValue: calculateDailyValue(totals.sugar, 50)
                                        },
                                        {
                                            label: 'Protein',
                                            amount: `${totals.protein.toFixed(2)}g`,
                                            dailyValue: calculateDailyValue(totals.protein, 50)
                                        },
                                        {
                                            label: 'Salt',
                                            amount: `${totals.salt.toFixed(2)}mg`,
                                            dailyValue: calculateDailyValue(totals.salt, 2300)
                                        },
                                    ],
                                    micronutrients: []
                                }}
                            />
                        </Box>
                    )}
                    {nutritionData && (
                        <Box sx={{ mt: 2 }}>
                            {nutritionData.map((item, idx) => (
                                <Box key={idx} sx={{ mb: 2 }}>
                          {/*          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {item.dishName}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                        Portion Size: {item.portionSize}g
                                    </Typography>*/}
                                    <NutritionLabel
                                        data={{
                                            name: item.dishName,
                                            servingsPerContainer: '1',
                                            servingSize: `${item.portionSize}g`,
                                            calories: (item.kilojoules * 0.239006 * item.portionSize / 100).toFixed(2),
                                            nutrients: [
                                                {
                                                    label: 'Fat',
                                                    amount: `${(item.fatG * item.portionSize / 100).toFixed(2)}g`,
                                                    dailyValue: calculateDailyValue(item.fatG * item.portionSize / 100, 78)
                                                },
                                                {
                                                    label: 'Saturated Fat',
                                                    amount: `${(item.fatSaturatedG * item.portionSize / 100).toFixed(2)}g`,
                                                    dailyValue: calculateDailyValue(item.fatSaturatedG * item.portionSize / 100, 20)
                                                },
                                                {
                                                    label: 'Carbohydrate',
                                                    amount: `${(item.carbohydratesG * item.portionSize / 100).toFixed(2)}g`,
                                                    dailyValue: calculateDailyValue(item.carbohydratesG * item.portionSize / 100, 275)
                                                },
                                                {
                                                    label: 'Sugars',
                                                    amount: `${(item.sugarG * item.portionSize / 100).toFixed(2)}g`,
                                                    dailyValue: calculateDailyValue(item.sugarG * item.portionSize / 100, 50)
                                                },
                                                {
                                                    label: 'Protein',
                                                    amount: `${(item.proteinG * item.portionSize / 100).toFixed(2)}g`,
                                                    dailyValue: calculateDailyValue(item.proteinG * item.portionSize / 100, 50)
                                                },
                                                {
                                                    label: 'Salt',
                                                    amount: `${(item.saltG * item.portionSize / 100).toFixed(2)}mg`,
                                                    dailyValue: calculateDailyValue(item.saltG * item.portionSize / 100, 2300)
                                                },
                                            ],
                                            micronutrients: [
                                                {
                                                    label: 'Calcium',
                                                    amount: `${(item.calciumMg * item.portionSize / 100).toFixed(2)}mg`,
                                                    dailyValue: calculateDailyValue(item.calciumMg * item.portionSize / 100, 1300)
                                                },
                                                {
                                                    label: 'Iron',
                                                    amount: `${(item.ironMg * item.portionSize / 100).toFixed(2)}mg`,
                                                    dailyValue: calculateDailyValue(item.ironMg * item.portionSize / 100, 18)
                                                },
                                                {
                                                    label: 'Potassium',
                                                    amount: `${(item.potassiumMg * item.portionSize / 100).toFixed(2)}mg`,
                                                    dailyValue: calculateDailyValue(item.potassiumMg * item.portionSize / 100, 4700)
                                                },
                                                {
                                                    label: 'Vitamin D',
                                                    amount: `${(item.vitaminD2Ug * item.portionSize / 100).toFixed(2)}mcg`,
                                                    dailyValue: calculateDailyValue(item.vitaminD2Ug * item.portionSize / 100, 20)
                                                },
                                                {
                                                    label: 'Vitamin C',
                                                    amount: `${(item.vitaminCMg * item.portionSize / 100).toFixed(2)}mg`,
                                                    dailyValue: calculateDailyValue(item.vitaminCMg * item.portionSize / 100, 90)
                                                },
                                                {
                                                    label: 'Vitamin A',
                                                    amount: `${(item.vitaminAUg * item.portionSize / 100).toFixed(2)}mcg`,
                                                    dailyValue: calculateDailyValue(item.vitaminAUg * item.portionSize / 100, 900)
                                                },
                                            ]
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

export default MealCard;
