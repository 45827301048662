import React, {Suspense} from 'react';
import Box from '@mui/material/Box';
import MealCard from '../Components/MealCard';
import dayjs from "dayjs";

const MealsWithGraph = ({date, meals, timelineEvents, symptoms, activities, high, yAxisMax}) => {

    const GlucoseGraph = React.lazy(() => import('../ListView/GlucoseGraph'));

    return meals.map((meal, mealIndex) => {
        const mealTime = new Date(meal.eatenAt);
        const endTime = new Date(mealTime.getTime() + 3 * 60 * 60 * 1000); // 3 hours after the meal
        const mealChartData = Array.from({length: 36}, (_, index) => { // 36 intervals for 3 hours
            return {
                date: new Date(mealTime.getTime() + index * 5 * 60000), // Every 5 minutes within the 3-hour window
                mealValue: index === 0 ? 50 : undefined, // Set meal value only for the first interval
                mealValueDetails: undefined,
                timelineValue: undefined,
                symptomValue: undefined,
                activityValue: undefined
            };
        });

        timelineEvents.forEach(event => {
            const eventTime = new Date(event.date);
            if (eventTime >= mealTime && eventTime <= endTime) {
                const index = mealChartData.findIndex(item => eventTime >= item.date && eventTime < new Date(item.date.getTime() + 5 * 60000));
                if (index !== -1) {
                    mealChartData[index].timelineValue = event.payload.value;
                }
            }
        });


        symptoms.forEach(symptom => {
            const symptomTime = new Date(symptom.startDate);
            if (symptomTime >= mealTime && symptomTime <= endTime) {
                const index = mealChartData.findIndex(item => symptomTime >= item.date && symptomTime < new Date(item.date.getTime() + 5 * 60000));
                if (index !== -1) {
                    mealChartData[index].symptomValue = symptom.intensity;
                }
            }
        });

        activities.forEach(activity => {
            const activityTime = new Date(activity.startDate);
            if (activityTime >= mealTime && activityTime <= endTime) {
                const index = mealChartData.findIndex(item => activityTime >= item.date && activityTime < new Date(item.date.getTime() + 5 * 60000));
                if (index !== -1) {
                    mealChartData[index].activityValue = activity.intensity;
                }
            }
        });

        return (
            <div key={mealIndex}>
                <Box sx={{
                    backgroundColor: '#FDD247',
                    paddingY: 0.4,
                }}>
                    <h3>
                        {dayjs(meal.eatenAt).format("ddd, MMM D, YYYY - H:m")}
                    </h3>
                </Box>
                <Box key={`$${mealIndex}`} sx={{display: "flex", padding: 4}}>
                    <Suspense fallback={<Box sx={{height: 300}}>Loading Graph...</Box>}>
                        <MealCard meal={meal} index={mealIndex} width={340} height={200}/>
                        <GlucoseGraph mealChartData={mealChartData} high={high} yAxisMax={yAxisMax}/>
                    </Suspense>
                </Box>
            </div>
        );
    });

};

export default MealsWithGraph;
