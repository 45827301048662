export function processDataForAGP(glucoseData) {
    const timeSeries = {};

    // Initialize placeholders for every hour
    for (let hour = 0; hour < 24; hour++) {
        const time = `${hour.toString().padStart(2, '0')}:00`;
        timeSeries[time] = [];  // Initialize each time slot with an empty array
    }

    // Populate the placeholders with glucose values grouped by the hour of the day
    glucoseData.forEach(event => {
        if (event.date && event.payload && event.payload.value != null) {
            const date = new Date(event.date);
            const hourOfDay = `${date.getUTCHours().toString().padStart(2, '0')}:00`;  // Use UTC to avoid time zone issues

            if (timeSeries.hasOwnProperty(hourOfDay)) {
                timeSeries[hourOfDay].push(event.payload.value);
            }
        }
    });

    const agpData = [];

    // Calculate percentiles for each hour slot, starting from 00:00
    for (const [time, values] of Object.entries(timeSeries)) {
        if (values.length > 0) {
            values.sort((a, b) => a - b);

            const p5 = percentile(values, 5);
            const p25 = percentile(values, 25);
            const p50 = percentile(values, 50);  // Median
            const p75 = percentile(values, 75);
            const p95 = percentile(values, 95);

            agpData.push({
                time,
                p5,
                p25,
                p50,
                p75,
                p95,
            });
        } else {
            // Fill empty time slots with nulls to ensure they are included in the output
            agpData.push({
                time,
                p5: null,
                p25: null,
                p50: null,
                p75: null,
                p95: null,
            });
        }
    }

    return agpData;
}

function percentile(arr, p) {
    if (arr.length === 0) return null;
    const index = Math.ceil(p / 100 * arr.length) - 1;
    return arr[index];
}
