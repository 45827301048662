import React from 'react';
import {Box, Typography, Divider, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NutritionLabel = ({data}) => {
    const roundDV = (value) => {
        if (isNaN(value)) return 'N/A';

        const roundedValue = Math.round(value);

        if (roundedValue < 1) {
            return '<1%';
        }

        return `${roundedValue}%`;
    };
    return (
        <Box sx={{border: '2px solid black', padding: 2, width: 300}}>
            <Typography variant="h4" fontWeight="bold">{data.name}</Typography>
            <Divider sx={{marginY: 1, borderWidth: '2px'}}/>
            <Box sx={{display: 'flex', justifyContent: 'space-between', fontWeight: 'bold'}}>
                <Typography>Serving size</Typography>
                <Typography>{data.servingSize}</Typography>
            </Box>
            <Divider sx={{marginY: 1, borderWidth: '8px'}}/>
            <Box sx={{display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', alignItems: 'baseline'}}>
                <Typography variant="h5">Calories</Typography>
                <Typography variant="h4">{data.calories}</Typography>
            </Box>
            <Divider sx={{marginY: 1, borderWidth: '4px'}}/>
            <Typography align="right" fontWeight="bold">% Daily Value*</Typography>
            {data.nutrients.map((nutrient, index) => (
                <Box key={index}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', fontWeight: 'bold'}}>
                        <Typography>{nutrient.label} {nutrient.amount}</Typography>
                        <Typography>{roundDV(nutrient.dailyValue)}</Typography>
                    </Box>
                    {index < data.nutrients.length - 1 && <Divider sx={{borderWidth: '1px'}}/>}
                </Box>
            ))}
            <Divider sx={{marginY: 1, borderWidth: '4px'}}/>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="micronutrients-content"
                                  id="micronutrients-header">
                    <Typography fontWeight="bold">Micronutrients</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {data.micronutrients.map((micronutrient, index) => (
                        <Box key={index}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', fontWeight: 'bold'}}>
                                <Typography>{micronutrient.label} {micronutrient.amount}</Typography>
                                <Typography>{roundDV( micronutrient.dailyValue)}</Typography>
                            </Box>
                            {index < data.micronutrients.length - 1 && <Divider sx={{borderWidth: '1px'}}/>}
                        </Box>
                    ))}
                </AccordionDetails>
            </Accordion>
            <Divider sx={{marginY: 1, borderWidth: '4px'}}/>
            <Typography fontSize="0.75rem">*The % Daily Value (DV) tells you how much a nutrient in a serving of food
                contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.</Typography>
        </Box>
    );
};

export default NutritionLabel;
