import {Typography, Box} from "@mui/material";
import React from "react";

export const getGMIDescriptionGerman = (gmi) => {
    return (
        <Box sx={{maxWidth: 600, paddingTop: 2, margin: 'auto', textAlign: 'left'}}>
            {gmi < 5.7 ? (
                <>
                    <Typography variant="subtitle1">
                        Ein GMI von <strong>{gmi}%</strong> ist ein gesunder Wert. Dies deutet darauf hin, dass Ihr
                        durchschnittlicher Glukosespiegel über die Wochen im gesunden    Bereich lag.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: 2}}>
                        Einen GMI in diesem Bereich zu halten, spricht für einen gesunden Lebensstil und einer
                        ausgewogenen Ernährung! Unter bestimmten Umständen kann es von Vorteil sein, Ihre Ernährung oder
                        Gewohnheiten leicht anzupassen. Veränderungen in der Ernährung können z.B. die
                        Schlafqualität, das Immunsystem und die Stressbewältigung positiv beeinflussen. Eine ausgewogene
                        Ernährung kann zudem die Konzentrationsfähigkeit steigern und zur Verbesserung der Stimmung
                        sowie der psychischen Gesundheit beitragen.
                    </Typography>
                </>
            ) : gmi >= 5.7 && gmi < 6.5 ? (
                <>
                    <Typography variant="subtitle1">
                        Ein GMI von <strong>{gmi}%</strong> liegt im Bereich des Nicht-Diabetes. Die Berechung basiert allerdings auf einer begrenzten Anzahl von Glukosedaten
                        der letzten zwei Wochen und kann daher abweichen. Eine Diagnose sollte immer durch einen Arzt erfolgen.
                    </Typography>
                    <Typography variant="subtitle1" sx={{marginTop: 2}}>
                        Es kann hilfreich sein, Ihre Glukosewerte regelmäßig zu überwachen und bei Bedarf kleine
                        Anpassungen in Ihrem Lebensstil vorzunehmen, wie zum Beispiel eine ausgewogenere Ernährung oder
                        mehr Bewegung im Alltag. Solche Veränderungen können unter bestimmten Umständen auch Ihre
                        Schlafqualität, Ihr Immunsystem und Ihre Fähigkeit zur Stressbewältigung verbessern.
                    </Typography>
                </>
            ) : gmi >= 6.5 && gmi < 7 ? (
                <>
                    <Typography variant="subtitle1">
                        Ein GMI von <strong>{gmi}%</strong> deutet darauf hin, dass Ihre Glukosewerte im diabetischen
                        Bereich liegen.
                        Dies entspricht einem durchschnittlichen Glukosespiegel, der auf Diabetes hinweist.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: 2}}>
                        Dieser GMI-Wert deutet darauf hin, dass Sie Ihren Diabetesmanagementplan mit Ihrem Arzt
                        überprüfen sollten.
                        Effektive Managementstrategien und möglicherweise Anpassungen bei Medikamenten könnten notwendig
                        sein, um Ihre Glukosewerte in einen gesünderen Bereich zu bringen.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant="subtitle1">
                        Ein GMI von <strong>{gmi}%</strong> ist hoch und zeigt, dass Ihre Glukosewerte nicht gut
                        kontrolliert sind.
                        Dies ist besorgniserregend und deutet darauf hin, dass Ihr durchschnittlicher Glukosespiegel in
                        den letzten 2-3 Monaten konstant hoch war.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: 2}}>
                        Sofortiges Handeln wird empfohlen. Bitte konsultieren Sie Ihren Arzt, um mögliche Anpassungen
                        Ihres Managementplans zu besprechen.
                        Hohe Glukosewerte über längere Zeit erhöhen das Risiko von Komplikationen, daher ist es wichtig,
                        dies umgehend anzugehen.
                    </Typography>
                </>
            )}
        </Box>
    );
};
