import * as React from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";

export default function BasicDatePicker({ value, setValue, label }) {
    const formattedDate = value ? dayjs(value) : null;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                disableFuture
                value={formattedDate}
                onChange={(newValue) => setValue(newValue)}
                label={label}
                renderInput={(params) => <TextField {...params} sx={{ width: 200, marginRight: 2 }} />}
            />
        </LocalizationProvider>
    );
}
