import {
    Area, Bar,
    CartesianGrid,
    ComposedChart,
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import dayjs from "dayjs";
import CustomTooltip from "./CustomTooltip";
import React from "react";


const DayGlucoseGraph = ({chartData, yAxisMax, high}) => {
    return (<ResponsiveContainer width="100%" height={450}>
        <ComposedChart data={chartData}>
            <CartesianGrid stroke={"none"} strokeDasharray="3 3" strokeWidth={0.5}/>
            <XAxis
                dataKey="date"
                type="number"
                domain={['dataMin', 'dataMax']}
                scale="time"
                interval={30}
                tickFormatter={timeStr => dayjs(timeStr).format('HH:mm')}/>
            <YAxis type="number" domain={[0, yAxisMax]} allowDataOverflow/>


            <Area
                type="monotone"
                dataKey={point => point.date >= new Date(point.date).setHours(7, 0, 0, 0) && point.date <= new Date(point.date).setHours(18, 0, 0, 0) ? yAxisMax : null} // Fill for daytime
                fill="#FFFDF3" // light yellowish shade for day
                isAnimationActive={false}
                tooltipType={"none"}
                activeDot={false}
                strokeWidth={0}
            />

            <Area
                type="monotone"
                dataKey={point => point.date < new Date(point.date).setHours(7, 0, 0, 0) || point.date > new Date(point.date).setHours(19, 0, 0, 0) ? yAxisMax : null} // Fill for nighttime
                fill="#DCDCF0" // dark bluish shade for night
                isAnimationActive={false}
                tooltipType={"none"}
                activeDot={false}
                strokeWidth={0}
            />

            <Area
                type="monotone"
                connectNulls={true}
                baseValue={70}
                isAnimationActive={false}
                dataKey="timelineValue"
                strokeWidth={5}
                fill={"transparent"}
            />
            <Tooltip
                reverseDirection={{x: true}}
                offset={50}
                content={<CustomTooltip/>}/>
            <Area
                type="monotone"
                connectNulls={false}
                isAnimationActive={false}

                dataKey={point => [70, Math.min(70, point.timelineValue)]}
                fill="red"  // Color for values below 70
                opacity={0.4}
                tooltipType={"none"}
                activeDot={false}
            />

            <Area
                type="monotone"
                connectNulls={false}
                isAnimationActive={false}

                activeDot={false}
                dataKey={point => point.timelineValue > high ? [point.timelineValue, high] : null}
                fill="blue"  // Color for values above 160
                opacity={0.4}
                tooltipType={"none"}
            />
            <ReferenceLine y={high} stroke="blue"/>
            <ReferenceLine y={70} stroke="red"/>
            <ReferenceLine y={92} strokeWidth={3} stroke="orange"/>

            <Scatter
                name="Activities"
                isAnimationActive={false}

                dataKey="activityValue"
                fill="green" // Choose an appropriate color
                shape="triangle"

            />
            <Scatter
                siz={100} // Example: size based on intensity
                name="Symptoms"
                isAnimationActive={false}

                dataKey="symptomValue"
                shape="triangle"
                fill="orange" // Choose an appropriate color
            />

            <Bar isAnimationActive={false}
                 dataKey="mealValue" barSize={15} fill="#413ea0" radius={[3, 3, 0, 0]}/>

        </ComposedChart>
    </ResponsiveContainer>)
}
export default DayGlucoseGraph
