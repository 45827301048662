export enum ErrorType {
  UNKNOWN = 'UNKNOWN',
  NETWORK = 'NETWORK',
  DEVELOPER = 'DEVELOPER',

  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
}
