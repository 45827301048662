import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from "dayjs";
import {Button, Typography} from "@mui/material";
import {CSVLink} from "react-csv";
import Lottie from "react-lottie";
import * as analysis from "./images/analyse.json";
import {headers} from "./CSVDownloadButton";
import GlucoseManagement from "./GMI/GlucoseManagement";
import {useServices} from "./providers/service-provider";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const TopMealsModal = ({
                           user,
                           isAdmin,
                           open,
                           handleClose,
                           topMeals,
                           isLoading,
                           csvData,
                           filename,
                           trendsByDay,
                           nutrientPercentages,
                           selectedUserId,
                           startDate,
                           endDate
                       }) => {
    const parsedNutrientPercentages = nutrientPercentages.map(item => ({
        ...item,
        value: parseFloat(item.value)
    }));
    const {authorizedHttpClient} = useServices();
    const maxAvgGlucose30Min = Math.max(...Object.values(trendsByDay).map(day => day.avgGlucose30MinAfter));
    const maxAvgGlucose2H = Math.max(...Object.values(trendsByDay).map(day => day.avgGlucose2HAfter));
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: analysis,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const downloadPDF = () => {
        const element = document.getElementById('modal-content');

        // Hide the buttons
        const buttons = document.querySelectorAll('.exclude-from-capture');
        buttons.forEach(button => {
            button.style.display = 'none';
        });

        // Expand the content to capture everything
        const originalMaxHeight = element.style.maxHeight;
        const originalOverflowY = element.style.overflowY;
        const originalBackgroundColor = element.style.backgroundColor;

        element.style.maxHeight = 'none';
        element.style.overflowY = 'visible';
        element.style.backgroundColor = '#fff';

        html2canvas(element, {
            useCORS: true,
            scale: 2 // Increase scale for better quality
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('report.pdf');

            // Restore the buttons and original styles
            buttons.forEach(button => {
                button.style.display = '';
            });
            element.style.maxHeight = originalMaxHeight;
            element.style.overflowY = originalOverflowY;
            element.style.backgroundColor = originalBackgroundColor;
        });
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box id="modal-content" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: '90%', sm: '80%', md: '70%', lg: '70%'},
                maxHeight: '80vh',
                overflowY: 'auto',
                borderRadius: 4,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3
            }}>
                <h2 style={{margin: 1}}>Report</h2>
                {isLoading ? (
                    <Box flex>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CircularProgress/>
                            <Typography sx={{p: 2, marginLeft: 2, marginTop: 2, fontSize: 18}}>
                                Hold on. We analyse for your data to find the biggest Glukose-Spikes
                            </Typography>
                        </Box>
                        <Lottie options={defaultOptions} height={400} width={400}/>
                    </Box>
                ) : (
                    <Box>
                        <GlucoseManagement user={user} selectedUserId={selectedUserId} isAdmin={isAdmin}
                                           authorizedHttpClient={authorizedHttpClient} startDate={startDate}
                                           endDate={endDate} parsedNutrientPercentages={parsedNutrientPercentages}/>

                        <TableContainer  className="exclude-from-capture" component={Paper}>
                            <Table sx={{minWidth: 800}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Dishes</TableCell>
                                        <TableCell align="right">Spike after 30 min (mg/dL)</TableCell>
                                        <TableCell align="center">Notes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {topMeals.map((meal, index) => (
                                        <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell component="th" scope="row">
                                                {meal['Gerichte']} <br/>
                                                {dayjs(meal['Mahlzeit Datum und Uhrzeit']).format("DD.MM.YY H:mm")}
                                            </TableCell>
                                            <TableCell align="right">{meal['Anstieg in 30 Min.']}</TableCell>
                                            <TableCell align="left">{meal['Notizen']}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <h3  className="exclude-from-capture">Average Glucose Trends by Day of Week</h3>
                        <TableContainer  className="exclude-from-capture" component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="average glucose trends">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Day of Week</TableCell>
                                        <TableCell align="right">Average Glucose 30 Min After Meal (mg/dL)</TableCell>
                                        <TableCell align="right">Average Glucose 2 Hours After Meal (mg/dL)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(trendsByDay).map(([day, data], index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">{day}</TableCell>
                                            <TableCell
                                                align="right"
                                                sx={data.avgGlucose30MinAfter === maxAvgGlucose30Min ? {
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#ffeb3b'
                                                } : null}
                                            >
                                                {data.avgGlucose30MinAfter.toFixed(2)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={data.avgGlucose2HAfter === maxAvgGlucose2H ? {
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#ffeb3b'
                                                } : null}
                                            >
                                                {data.avgGlucose2HAfter.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br/>
                        <Button variant="outlined" className="exclude-from-capture">
                            <CSVLink
                                data={csvData}
                                headers={headers}
                                filename={filename}
                                style={{color: '#000', textDecoration: 'none'}}
                            >
                                Download CSV
                            </CSVLink>
                        </Button>
                        <Button variant="contained" color="primary" onClick={downloadPDF}
                                className="exclude-from-capture" style={{marginLeft: '10px'}}>
                            Download as PDF
                        </Button>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};
export default TopMealsModal
