import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const GlucoseTimeInRangeChart = ({ timeInRangeData }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                if (chartInstance.current) {
                    chartInstance.current.destroy(); // Destroy the existing chart instance
                }
                chartInstance.current = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: ['Glucose Ranges'],
                        datasets: [
                            {
                                label: `Low (<70) - ${timeInRangeData.low}%`,
                                data: [timeInRangeData.low],
                                backgroundColor: 'red',
                            },
                            {
                                label: `In Range (70-140) - ${timeInRangeData.inRange}%`,
                                data: [timeInRangeData.inRange],
                                backgroundColor: 'green',
                            },
                            {
                                label: `High (141-200) - ${timeInRangeData.high}%`,
                                data: [timeInRangeData.high],
                                backgroundColor: 'orange',
                            },
                            {
                                label: `Very High (>200) - ${timeInRangeData.veryHigh}%`,
                                data: [timeInRangeData.veryHigh],
                                backgroundColor: 'darkorange',
                            },
                        ],
                    },
                    options: {
                        indexAxis: 'y', // Horizontal bars
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (tooltipItem) {
                                        const label = tooltipItem.dataset.label || '';
                                        return `${label}: ${tooltipItem.raw}%`;
                                    },
                                },
                            },
                            legend: {
                                position: 'bottom',
                                align: 'start', // Left-align the legend text
                            },
                        },
                        scales: {
                            x: {
                                stacked: true,
                                display: false, // Hide the x-axis
                            },
                            y: {
                                stacked: true,
                                display: false, // Hide the y-axis
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    },
                });
            }
        }
    }, [timeInRangeData]);

    return (
        <div style={{ flex: 1, marginRight: '20px', textAlign: 'center' }}>
            <h3>Time in Range</h3>
            <div style={{ width: '300px', height: '200px', margin: '0 auto' }}>
                <canvas ref={chartRef}></canvas>
            </div>
        </div>
    );
};

export default GlucoseTimeInRangeChart;
