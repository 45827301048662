export const calculateTimeInRange = (timelineEvents, mealTime, endTime) => {

    const readingsInRange = timelineEvents.filter(event => {
        const eventTime = new Date(event.date);
        return eventTime >= mealTime && eventTime <= endTime && event.payload.value >= 70 && event.payload.value <= 140;
    }).length;

    const totalReadings = timelineEvents.filter(event => {
        const eventTime = new Date(event.date);
        return eventTime >= mealTime && eventTime <= endTime;
    }).length;

    return totalReadings > 0 ? (readingsInRange / totalReadings) * 100 : null; // Returns the percentage or null if no readings
};
