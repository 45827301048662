import {useState, useEffect} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword} from 'firebase/auth';
import Maps3 from "./Maps/Maps3";
import {ToastContainer, toast} from 'react-toastify';
import "./App.css";

import 'react-toastify/dist/ReactToastify.css';
import Home from './Home';
import BasicTextFields from './Components/Form';
import {createTheme, ThemeProvider} from "@mui/material/styles";



function App() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

// Create a theme instance.
    let theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(56,101,189)',
            },
            secondary: {
                main: '#edf2ff',
            },
        },
    });

    // Function to handle key down
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            console.log("ENTER")
            handleAction(1); // Adjust this based on your logic
        }
    };

    // Setting up and cleaning the keydown event listener
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // Empty dependency array to run only on mount and unmount
    const handleAction = async (id) => {
        const authentication = getAuth();
        try {
            if (id === 1) {
                const response = await signInWithEmailAndPassword(authentication, email, password);
                navigate('/');
                sessionStorage.setItem('Auth Token', response.user.refreshToken);
            } else if (id === 2) {
                const response = await createUserWithEmailAndPassword(authentication, email, password);
                navigate('/');
                sessionStorage.setItem('Auth Token', response.user.refreshToken);
            }
        } catch (error) {
            console.log(error.code);
            if (error.code === 'auth/wrong-password') {
                toast.error('Please check the Password');
            } else if (error.code === 'auth/user-not-found') {
                toast.error('Please check the Email');
            } else if (error.code === 'auth/email-already-in-use') {
                toast.error('Email Already in Use');
            }
        }
    };

    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token');

        if (authToken && navigate && navigate.current && navigate.current.pathname) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div className="App">
            <ToastContainer/>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/maps" element={<Maps3/>}/>

                    <Route
                        path="/login"
                        element={
                            <BasicTextFields theme={theme} title="Login" setEmail={setEmail}
                                             setPassword={setPassword}
                                             handleAction={() => handleAction(1)}/>
                        }
                    />
                    <Route
                        path="/register"
                        element={<BasicTextFields title="Register" setEmail={setEmail} setPassword={setPassword}
                                                  handleAction={() => handleAction(2)}/>}
                    />
                    <Route path="/" element={<Home/>}/>
                    <Route path="*" element={<div>not found</div>}/>
                </Routes>
            </ThemeProvider>
        </div>
    );
}

export default App;
