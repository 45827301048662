import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import 'firebase/auth';
import DataAnalyzer from "./Charts/DataAnalyizer";
import {auth} from './firebase';
import {Box, Button} from "@mui/material";


export default function Home() {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [isAdmin, setAdmin] = useState(false)
    const handleLogout = () => {
        sessionStorage.removeItem('Auth Token');
        navigate('/login');
    };

    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token');
        if (!authToken) {
            navigate('/login');
        }

        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                const customAttributes = user.reloadUserInfo.customAttributes
                if (customAttributes) {
                    const jsonCustomAttributes = JSON.parse(customAttributes);
                    console.log(jsonCustomAttributes.role)
                    if (jsonCustomAttributes.role === "ADMIN") {
                        setAdmin(true)
                    }
                }
                console.log("user1",user)
                console.log("user", user.reloadUserInfo.customAttributes)
                setUser(user);
            } else {
                setUser(null);
                navigate('/login');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    return (
        <Box sx={{backgroundColor: '#e8e8e8'}}>
            <Button onClick={handleLogout}>Log out</Button>
            {user && <DataAnalyzer isAdmin={isAdmin} user={user.uid}/>}
        </Box>
    );
}
