// UserSelectDropdown.js
import React, {useState} from 'react';
import {Box, Button, Menu, MenuItem} from '@mui/material';
import {Person2Rounded} from "@mui/icons-material";

function UserSelectDropdown({userIDS, selectedUserName, onUserSelect, user}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (userId, userName) => {
        onUserSelect(userId, userName); // Pass both userId and userName
        handleClose();
    };
    return (
        <Box sx={{
            justifyContent: 'center',
            margin: 1,
        }}>
            <Button startIcon={<Person2Rounded/>} onClick={handleClick}>
                {selectedUserName}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => handleSelect(user, "Current User")}
                >
                    {user}
                </MenuItem>
                {userIDS.map(item => (

                    <MenuItem
                        key={item.id}
                        onClick={() => handleSelect(item.id, item.name)}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default UserSelectDropdown;
