export function tirToColor(tir) {
    let red, green;
    if (tir <= 50) {
        // From 0% to 50%, we move from red (255,0,0) to yellow (255,255,0)
        red = 255;
        green = Math.round((tir / 50) * 255); // Increase green component to transition to yellow
    } else {
        // From 50% to 100%, move from yellow (255,255,0) to green (0,255,0)
        green = 255;
        red = Math.round(((100 - tir) / 50) * 255); // Decrease red component to transition to green
    }

    return `rgb(${red}, ${green}, 0)`;
}

