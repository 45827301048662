import React from "react";
function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`Date: ${new Date(label).toLocaleString()}`}</p>

                {payload.map((item, index) => {
                    // Check for mealValueDetails (from the bar)
                    if (item.dataKey === 'mealValue' && item.payload['mealValueDetails']) {
                        const mealDetails = item.payload['mealValueDetails'];
                        const place = mealDetails.preparationPlaces && mealDetails.preparationPlaces[0]
                            ? mealDetails.preparationPlaces[0].name
                            : "";
                        const image = mealDetails.image ? mealDetails.image : null;
                        return (
                            <div key={index} className="intro">
                                {image && (
                                    <img
                                        src={image}
                                        alt={`Meal ${index}`}
                                        style={{ width: "230px", height: "auto" }}
                                    />
                                )}
                                <p>
                                    {`${mealDetails.name}`}
                                    {place && <span> (Prepared at: {place})</span>}
                                </p>
                            </div>
                        );
                    }

                    // Check for timelineValue (from the first area)
                    else if (item.dataKey === 'timelineValue') {
                        return (
                            <p key={index} className="intro">
                                {`Glukose: ${item.value}`}
                            </p>
                        );
                    }
                    // Check for symptom details
                    if (item.dataKey === 'symptomValue' && item.payload['symptomDetails']) {
                        const symptomDetails = item.payload['symptomDetails'];
                        return (
                            <p key={`symptom-${index}`} className="intro">
                                {`Symptom: ${symptomDetails.symptomType}, Intensity: ${symptomDetails.intensity}`}
                            </p>
                        );
                    }

                    // Check for activity details
                    if (item.dataKey === 'activityValue' && item.payload['activityDetails']) {
                        const activityDetails = item.payload['activityDetails'];
                        return (
                            <p key={`activity-${index}`} className="intro">
                                {`Activity: ${activityDetails.activityType}, Duration: ${activityDetails.duration} mins`}
                            </p>
                        );
                    }


                    return null;  // For any other dataKey, return null (no tooltip)
                })}
            </div>
        );
    }

    return null;
}

export default CustomTooltip;
