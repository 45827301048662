import React, {useEffect, useRef, useState} from "react";

import MyMarker from "./MyMarkers";
import "./styles.css";
import GoogleMap from "google-maps-react-markers";
import {PlacesDropdown, PlacesList} from "./PlacesList";


export default function Maps3(props) {

    const mapRef = useRef(null)
    const [mapReady, setMapReady] = useState(false)
    const [center, setCenter] = useState({lat: 0, lng: 0});
    const [selectedMarkerId, setSelectedMarkerId] = useState(null);

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const total = props.data.reduce(
                (acc, {lat, lng}) => {
                    acc.lat += lat;
                    acc.lng += lng;
                    return acc;
                },
                {lat: 0, lng: 0}
            );
            const newCenter = {
                lat: total.lat / props.data.length,
                lng: total.lng / props.data.length,
            };
            setCenter(newCenter);

            // If the map is ready, update the center directly
            if (mapReady && mapRef.current) {
                mapRef.current.setCenter(newCenter);
            }
        }
    }, [props.data, mapReady]);

    /**
     * @description This function is called when the map is ready
     * @param {Object} map - reference to the map instance
     * @param {Object} maps - reference to the maps library
     */
    const onGoogleApiLoaded = ({map, maps}) => {
        mapRef.current = map
        setMapReady(true)
        // If center is already calculated, set the map center
        if (center) {
            mapRef.current.setCenter(center);
        }
    }

    const onMarkerClick = (e, {markerId, lat, lng}) => {
        console.log('This is ->', markerId)

        // inside the map instance you can call any google maps method
        mapRef.current.setCenter({lat, lng})
        // ref. https://developers.google.com/maps/documentation/javascript/reference?hl=it
    }

    const onSelectMarker = (id) => {
        const selectedMarker = props.data.find(marker => marker.id === id);
        if (selectedMarker && mapRef.current) {
            // Update the map's center to the selected marker's location
            mapRef.current.setCenter({ lat: selectedMarker.lat, lng: selectedMarker.lng });
        }
        setSelectedMarkerId(id);
    };


    return (
        <>
            <PlacesDropdown data={props.data} onPlaceSelect={onSelectMarker} />

            <GoogleMap
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                defaultCenter={center || {lat: 45.4046987, lng: 12.2472504}} // Fallback center

                defaultZoom={12}
                //options={mapOptions}
                mapMinHeight="100vh"
                onGoogleApiLoaded={onGoogleApiLoaded}
                onChange={(map) => console.log('Map moved', map)}
            >
                {props.data.map(({lat, lng, name, id, avgTIR}) => (
                    <MyMarker
                        key={id}
                        lat={lat}
                        lng={lng}
                        text={name}
                        isSelected={id === selectedMarkerId}
                        tooltip={`Location: ${name}`}
                        avgTIR={avgTIR} // Pass the avgTIR prop to each marker
                    />
                ))}
            </GoogleMap>
        </>
    );
}
