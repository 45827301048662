import {useCallback} from 'react';

export const useFetchMeals = (authorizedHttpClient) => {
    const fetchMeals = useCallback(async (meals) => {
        const dishIds = meals.flatMap(meal => meal?.dishes?.map(item => item.id) || []);

        if (dishIds.length === 0) {
            console.log("No dish IDs found. Skipping fetch.");
            return [];
        }

        const urlParam = `?dishIds=${dishIds.join(',')}`;
        try {
            const response = await authorizedHttpClient.jsonRequest('GET', `/api/nutrition${urlParam}`);
            console.log(response)
            return response;
        } catch (error) {
            console.error("Error fetching nutrition data", error);
            return [];
        }
    }, [authorizedHttpClient]);

    return fetchMeals;
};


export const useFetchUserMealNutritionData = (authorizedHttpClient) => {
    const fetchUserMealNutritionData = useCallback(async (userMealId) => {
        try {
            const response = await authorizedHttpClient.jsonRequest('GET', `/api/nutrition/usermeal/${userMealId}`);
            return response;
        } catch (error) {
            console.error(`Error fetching nutrition data for user meal ${userMealId}`, error);
            return null; // Handle errors gracefully
        }
    }, [authorizedHttpClient]);

    return fetchUserMealNutritionData;
};


export const useFetchNutritionData = (authorizedHttpClient) => {

    const fetchNutritionData = useCallback(async (meals) => {
        const nutritionData = await Promise.all(
            meals.flatMap(meal =>
                meal.dishes.map(async dish => {
                    console.log(dish)
                    if (dish.nutritionId) {
                        try {
                            const response = await authorizedHttpClient.jsonRequest('GET', `/api/nutrition/${dish.nutritionId}`);
                            return response;
                        } catch (error) {
                            console.error(`Error fetching nutrition data for dish ${dish.name}`, error);
                            return null; // Handle errors individually
                        }
                    }
                    return null; // No nutrition ID provided
                })
            )
        );
        return nutritionData.filter(data => data !== null); // Filter out null values
    }, [authorizedHttpClient]);

    return fetchNutritionData;
};
