// src/components/NutrientBreakdownChart.js

import React from 'react';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

const NutrientBreakdownChart = ({ parsedNutrientPercentages, COLORS }) => (
    <div style={{ flex: 1, textAlign: 'center' }}>
        <h3>Nutrient Breakdown</h3>
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
                <Pie
                    data={parsedNutrientPercentages}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                >
                    {parsedNutrientPercentages.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    </div>
);

export default NutrientBreakdownChart;
