import React, { useRef, useEffect } from 'react';
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, Filler } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register necessary components
Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, Filler, annotationPlugin);

const GlucoseAGPChart = ({ agpData }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        const config = {
            type: 'line',
            data: {
                labels: agpData.map(entry => entry.time),  // Times on the X-axis
                datasets: [




                    {
                        label: 'Median (P50)',
                        data: agpData.map(entry => entry.p50),
                        borderColor: '#000000',  // Dark blue line
                        backgroundColor: 'rgba(0, 0, 255, 0)',  // No fill
                        fill: false,  // No fill for the median
                        borderWidth: 2,
                        tension: 0.4
                    },
                    {
                        label: 'P25',
                        data: agpData.map(entry => entry.p25),
                        borderColor: 'rgba(0, 0, 255, 0)',  // Transparent border
                        backgroundColor: 'rgba(0, 0, 255, 0)',  // Transparent, no fill
                        fill: false,  // No fill
                        tension: 0.4
                    },
                    {
                        label: 'P75',
                        data: agpData.map(entry => entry.p75),
                        borderColor: 'rgba(0, 0, 255, 0)',  // Transparent border
                        backgroundColor: 'rgba(0, 0, 255, 0.7)',  // Mid blue fill
                        fill: '-1',  // Fill down to P25 dataset
                        tension: 0.4
                    },
                    {
                        label: 'P5',
                        data: agpData.map(entry => entry.p5),
                        borderColor: 'rgba(0, 0, 255, 0)',  // Transparent border
                        backgroundColor: 'rgba(0, 0, 255, 0)',  // Transparent, no fill
                        fill: false,  // No fill
                        tension: 0.4
                    },
                    {
                        label: 'P95',
                        data: agpData.map(entry => entry.p95),
                        borderColor: 'rgba(0, 0, 255, 0)',  // Transparent border
                        backgroundColor: 'rgba(173, 216, 230, 0.5)',  // Light blue fill
                        fill: '-1',  // Fill down to P5 dataset
                        tension: 0.4
                    },
                ],
            },
            options: {
                responsive: true,
                scales: {
                    x: {
                        type: 'category',
                        title: {
                            display: true,
                            text: 'Time of Day',
                        },
                    },
                    y: {
                        stacked: false,  // Set to false since we are not stacking cumulative data
                    },
                },
                plugins: {
                    filler: {
                        propagate: false,  // Ensures fill only affects intended datasets
                    },
                    legend: {
                        display: false,  // Hide the legend if not needed
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                    },
                    annotation: {
                        annotations: {
                            line1: {
                                type: 'line',
                                yMin: 70,
                                yMax: 70,
                                borderColor: 'green',
                                borderWidth: 2,
                                label: {
                                    content: '70 mg/dL',
                                    enabled: true,
                                    position: 'end',
                                    backgroundColor: 'green',
                                    color: 'white',
                                },
                            },
                            line2: {
                                type: 'line',
                                yMin: 140,
                                yMax: 140,
                                borderColor: 'red',
                                borderWidth: 2,
                                label: {
                                    content: '140 mg/dL',
                                    enabled: true,
                                    position: 'end',
                                    backgroundColor: 'red',
                                    color: 'white',
                                },
                            },
                        },
                    },
                },
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x',
                },
            },
        };

        const chartInstance = new Chart(ctx, config);

        return () => {
            chartInstance.destroy();
        };
    }, [agpData]);

    return <canvas ref={chartRef} />;
};

export default GlucoseAGPChart;
