import React, {useEffect, useState, useCallback} from 'react';
import GlucoseAGPGraph from './GlucoseAGPGraph';
import GlucoseTimeInRangeChart from './GlucoseTimeInRangeChart';
import NutrientBreakdownChart from './NutrientBreakdownChart';
import {Typography, Box} from '@mui/material';
import {format, isValid, getHours} from 'date-fns';
import {useFetchGlucoseData} from "./useFetchGlucoseData";
import {processDataForAGP} from "./processDataForAGP";
import AvarageSoberGlucoseTable from "./AvarageSoberGlucoseTable";
import {getGMIDescriptionGerman} from "./GMIDescriptionGerman";

const GlucoseManagement = ({
                               user,
                               selectedUserId,
                               isAdmin,
                               authorizedHttpClient,
                               startDate,
                               endDate,
                               parsedNutrientPercentages
                           }) => {
    const [glucoseData, setGlucoseData] = useState([]);
    const [averageSoberGlucose, setAverageSoberGlucose] = useState([]);
    const [gmi, setGmi] = useState(null);
    const [timeInRangeData, setTimeInRangeData] = useState({low: 0, inRange: 0, high: 0, veryHigh: 0});
    const [loading, setLoading] = useState(true);
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const fetchGlucoseData = useFetchGlucoseData(authorizedHttpClient, selectedUserId, isAdmin);

    const loadGlucoseData = useCallback(async () => {
        setLoading(true);
        const fromDate = startDate;
        const tillDate = endDate;

        const data = await fetchGlucoseData(fromDate, tillDate);
        if (data && data.length > 0) {
            // Calculate average glucose
            const totalGlucose = data.reduce((sum, event) => sum + event.payload.value, 0);
            const averageGlucose = totalGlucose / data.length;

            // Calculate GMI
            const calculatedGmi = 3.31 + 0.02392 * averageGlucose;
            setGmi(calculatedGmi.toFixed(2));

            // Transform the fetched data into the format required for AGP
            const agpData = processDataForAGP(data);
            setGlucoseData(agpData);

            // Calculate time in range percentages
            const timeLow = data.filter(event => event.payload.value < 70).length;
            const timeInRange = data.filter(event => event.payload.value >= 70 && event.payload.value <= 140).length;
            const timeHigh = data.filter(event => event.payload.value > 140 && event.payload.value <= 200).length;
            const timeVeryHigh = data.filter(event => event.payload.value > 200).length;
            const totalTime = data.length;

            setTimeInRangeData({
                low: ((timeLow / totalTime) * 100).toFixed(2),
                inRange: ((timeInRange / totalTime) * 100).toFixed(2),
                high: ((timeHigh / totalTime) * 100).toFixed(2),
                veryHigh: ((timeVeryHigh / totalTime) * 100).toFixed(2),
            });

            // Calculate average sober glucose (between 2:00 and 6:00)
            const filteredData = data.filter(event => {
                const dateString = event.date;  // Use event.date instead of event.payload.date
                if (!dateString) {
                    console.warn('Missing date in event:', event);
                    return false;
                }

                const eventDate = new Date(dateString);  // Use Date constructor instead of parseISO

                if (!isValid(eventDate)) {
                    console.error('Invalid date:', dateString);
                    return false;
                }

                const eventHour = getHours(eventDate);
                return eventHour >= 2 && eventHour < 6;
            });

            const dailySoberGlucose = filteredData.reduce((acc, event) => {
                const eventDate = new Date(event.date);  // Use Date constructor instead of parseISO
                if (!isValid(eventDate)) return acc;

                const date = format(eventDate, 'yyyy-MM-dd');
                if (!acc[date]) acc[date] = [];
                acc[date].push(event.payload.value);
                return acc;
            }, {});

            const averages = Object.keys(dailySoberGlucose).map(date => {
                const values = dailySoberGlucose[date];
                const average = values.reduce((sum, value) => sum + value, 0) / values.length;
                return {date, average: average.toFixed(2)};
            });

            setAverageSoberGlucose(averages);

            setLoading(false);
        } else {
            setGmi(null);
            setLoading(false);
        }
    }, [fetchGlucoseData, startDate, endDate]);

    useEffect(() => {
        loadGlucoseData();
    }, [loadGlucoseData]);

    const formatDate = (date) => {
        const parsedDate = new Date(date);
        if (!isValid(parsedDate)) {
            return 'Date not available';
        }
        return format(parsedDate, 'MMMM d, yyyy');
    };

    const getColorByGMI = (gmi) => {
        if (gmi < 5.7) {
            return "green";
        } else if (gmi >= 5.7 && gmi < 6.5) {
            return "#829335";
        } else if (gmi >= 6.5 && gmi < 7) {
            return "orange";
        } else {
            return "#000";
        }
    };


    return (
        <div>
            {loading ? (
                <Typography>Loading...</Typography>
            ) : (
                <>
                    <Box textAlign="center" marginBottom={3}>
                        <Typography variant="h4">Ernährungsreport</Typography>
                        <Typography variant="subtitle1">{formatDate(startDate)} - {formatDate(endDate)}</Typography>
                    </Box>

                    {gmi !== null ? (
                        <Box textAlign="center" marginBottom={3}>
                            <Typography variant="h2" sx={{color: getColorByGMI(gmi)}}>
                                {gmi}%
                            </Typography>
                            <Typography variant="h6" color="textSecondary">
                                Glucose Management Indicator
                            </Typography>
                            <Typography variant="body2" color="textSecondary"
                                        sx={{marginTop: 1, marginBottom: 2, maxWidth: 600, margin: 'auto'}}>
                                Der Glukose-Management-Indikator (GMI) ermittelt Ihren durchschnittlichen Blutzuckerspiegel über
                                die letzten Wochen und hilft bei der Überwachung langfristiger Glukosetrends.
                            </Typography>
                            {getGMIDescriptionGerman(gmi)}
                        </Box>
                    ) : (
                        <Typography>No glucose data available for GMI calculation.</Typography>
                    )}

                    {glucoseData.length > 0 ? (
                        <>
                            <GlucoseAGPGraph agpData={glucoseData}/>

                            <div style={{display: 'flex', justifyContent: 'space-between', margin: '20px 0'}}>
                                <GlucoseTimeInRangeChart timeInRangeData={timeInRangeData}/>
                                <NutrientBreakdownChart parsedNutrientPercentages={parsedNutrientPercentages}
                                                        COLORS={COLORS}/>
                            </div>


                            <AvarageSoberGlucoseTable averageSoberGlucose={averageSoberGlucose}/>
                        </>
                    ) : (
                        <Typography>No glucose data available for the last two weeks.</Typography>
                    )}
                </>
            )}
        </div>
    );
};

export default GlucoseManagement;
