import React from 'react';

import {createUseContextHook} from '@lumind-solutions/react-hooks/create-use-context-hook';
import {HttpClient} from "../../services/http-client";


export interface Services {
    authorizedHttpClient: HttpClient;

}

interface Props {
    children: React.ReactNode;
    services: any;
}

const ServiceContext = React.createContext<Services | null>(null);
ServiceContext.displayName = 'ServiceContext';

export const ServiceProvider = ({children, services}: Props) => {
    return (
        <ServiceContext.Provider value={services}>
            {children}
        </ServiceContext.Provider>
    );
};

export const useServices = createUseContextHook(ServiceContext);
