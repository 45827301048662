import React from 'react';
import {Box, Grid, TextField, Button} from '@mui/material';
import {ReactComponent as MySvg} from '../images/analytics_login.svg';
import {ReactComponent as Logo} from '../images/meala_logo.svg';


export default function BasicTextFields({title, setPassword, setEmail, handleAction, theme}) {
    return (
        <Grid container spacing={0} style={{height: '100%'}}>
            <Logo style={{position:"absolute", top: 20, left: 20}} width={80}/>
            <Grid item xs={12} md={6} style={{
                backgroundColor: '#f5f5f5',
                display: 'flex',
                justifyContent: 'center',

            }}>

                <Box style={{marginTop:"30%", maxWidth: 600, width: '100%', padding: '1rem', backgroundColor:'#ffffff', height:400, borderRadius:20}}>

                    <Box className="heading-container" >
                        <h2>LOGIN</h2>
                        <h4>Analyse your glucose readings with meala – dashboard</h4>

                    </Box>

                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': {m: 1, width: '25ch'},
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="email"
                            label="Enter the Email"
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            id="password"
                            label="Enter the Password"
                            variant="outlined"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button variant="contained" color="primary" onClick={handleAction} style={{width:160, height:45,margin: '1rem'}}>
                            {title}
                        </Button>

                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}
                  style={{backgroundColor: 'rgb(227,210,169)' /* Replace with your desired color */}}>
                <Box style={{
                    maxWidth: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <MySvg/>
                </Box>
            </Grid>

        </Grid>
    );
}
