import React, {useState} from 'react';
import {CSVLink} from 'react-csv';
import Button from '@mui/material/Button';
import {CircularProgress} from "@mui/material";
import {analyseSpikes} from "./analyseSpikes";
import {analyzeTrendsByDayOfWeek} from "./anlyseMealsByDay";
import TopMealsModal from "./TopMealsModal";
import {useServices} from "./providers/service-provider";
import {useFetchUserMealNutritionData} from "./api";

export const headers = [
    {label: "Mahlzeit Datum und Uhrzeit", key: "Mahlzeit Datum und Uhrzeit"},
    {label: "Gerichte", key: "Gerichte"},
    {label: "Notizen", key: "Notizen"},
    {label: "Glukose bei Mahlzeit", key: "Glukose bei Mahlzeit"},
    {label: "Glukose 30 Min. nach Mahlzeit", key: "Glukose 30 Min. nach Mahlzeit"},
    {label: "Glukose 2 Std. nach Mahlzeit", key: "Glukose 2 Std. nach Mahlzeit"},
    {label: "Anstieg in 30 Min.", key: "Anstieg in 30 Min."},
    {label: "Sum kCal", key: "Sum kCal"},
    {label: "Sum Carbs (g)", key: "Sum Carbs (g)"},
    {label: "Sum Fat (g)", key: "Sum Fat (g)"},
    {label: "Sum Protein (g)", key: "Sum Protein (g)"},
    {label: "Sum Salt (g)", key: "Sum Salt (g)"},
    {label: "Sum Sugar (g)", key: "Sum Sugar (g)"},
    {label: "Nutrition Data", key: "Nutrition Data"},
];

const CSVDownloadButton = ({rawData, filename, user, isAdmin, selectedUserId, startDate,endDate}) => {
    const [csvData, setCsvData] = useState([]);
    const [isCSVReady, setIsCSVReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [topMeals, setTopMeals] = useState([]);
    const [trendsByDay, setTrendsByDay] = useState([]);
    const [nutrientPercentages, setNutrientPercentages] = useState([]);
    const {authorizedHttpClient} = useServices();
    const fetchUserMealNutritionData = useFetchUserMealNutritionData(authorizedHttpClient);

    const fetchAndAugmentData = async () => {
        let totalCarbs = 0;
        let totalProtein = 0;
        let totalFat = 0;

        for (const date in rawData) {
            if (rawData.hasOwnProperty(date)) {
                const dayData = rawData[date];
                for (const meal of dayData.meals) {
                    const nutritionData = await fetchUserMealNutritionData(meal.id);

                    if (nutritionData && Array.isArray(nutritionData)) {
                        let sumkCal = 0;
                        let sumCarbs = 0;
                        let sumFat = 0;
                        let sumProtein = 0;
                        let sumSalt = 0;
                        let sumSugar = 0;

                        meal.dishes = meal.dishes.map(dish => {
                            const nutrition = nutritionData.find(nut => nut.dishId === dish.id);
                            const portionMultiplier = parseFloat(nutrition.portionSize || 0) / 100;

                            const kCal = nutrition ? (nutrition.kilojoules * 0.239006 * portionMultiplier).toFixed(2) : 'N/A';
                            const carbsG = nutrition ? (nutrition.carbohydratesG * portionMultiplier).toFixed(2) : 'N/A';
                            const fatG = nutrition ? (nutrition.fatG * portionMultiplier).toFixed(2) : 'N/A';
                            const proteinG = nutrition ? (nutrition.proteinG * portionMultiplier).toFixed(2) : 'N/A';
                            const saltG = nutrition ? (nutrition.saltG * portionMultiplier).toFixed(2) : 'N/A';
                            const sugarG = nutrition ? (nutrition.sugarG * portionMultiplier).toFixed(2) : 'N/A';

                            sumkCal += parseFloat(kCal) || 0;
                            sumCarbs += parseFloat(carbsG) || 0;
                            sumFat += parseFloat(fatG) || 0;
                            sumProtein += parseFloat(proteinG) || 0;
                            sumSalt += parseFloat(saltG) || 0;
                            sumSugar += parseFloat(sugarG) || 0;

                            return {
                                ...dish,
                                kCal: kCal,
                                fatG: fatG,
                                carbsG: carbsG,
                                proteinG: proteinG,
                                saltG: saltG,
                                sugarG: sugarG,
                            };
                        });

                        totalCarbs += sumCarbs;
                        totalProtein += sumProtein;
                        totalFat += sumFat;

                        meal.sumkCal = sumkCal.toFixed(2);
                        meal.sumCarbs = sumCarbs.toFixed(2);
                        meal.sumFat = sumFat.toFixed(2);
                        meal.sumProtein = sumProtein.toFixed(2);
                        meal.sumSalt = sumSalt.toFixed(2);
                        meal.sumSugar = sumSugar.toFixed(2);
                    } else {
                        console.warn(`No nutrition data available for meal ${meal.id}`);
                        meal.dishes = meal.dishes.map(dish => ({
                            ...dish,
                            kCal: 'N/A',
                            fatG: 'N/A',
                            carbsG: 'N/A',
                            proteinG: 'N/A',
                            saltG: 'N/A',
                            sugarG: 'N/A',
                        }));
                        meal.sumkCal = 'N/A';
                        meal.sumCarbs = 'N/A';
                        meal.sumFat = 'N/A';
                        meal.sumProtein = 'N/A';
                        meal.sumSalt = 'N/A';
                        meal.sumSugar = 'N/A';
                    }
                }
            }
        }

        // Filter out zero nutrients
        const nutrients = [
            {name: 'Carbs', value: totalCarbs},
            {name: 'Protein', value: totalProtein},
            {name: 'Fat', value: totalFat}
        ].filter(nutrient => nutrient.value > 0);

        const totalNutrients = nutrients.reduce((sum, nutrient) => sum + nutrient.value, 0);

        if (totalNutrients > 0) {
            const percentageData = nutrients.map(nutrient => ({
                name: nutrient.name,
                value: ((nutrient.value / totalNutrients) * 100).toFixed(2)
            }));
            console.log('Nutrient Percentages:', percentageData);  // Debug log
            setNutrientPercentages(percentageData);
        } else {
            console.log('No valid nutrient data to display');  // Debug log
            setNutrientPercentages([]); // No data to display
        }

        return rawData;
    };

    const handleDownloadClick = async () => {
        if (!csvData.length) {
            setIsLoading(true);
            setShowModal(true);

            const augmentedData = await fetchAndAugmentData(); // Fetch and augment the data

            const worker = new Worker(new URL('./dataProcessingWorker.js', import.meta.url));
            worker.postMessage(augmentedData);

            worker.onmessage = async (e) => {
                const analysisResults = e.data;
                analysisResults.sort((a, b) => b['Anstieg in 30 Min.'] - a['Anstieg in 30 Min.']);

                const top10Meals = analysisResults.slice(0, 10);
                setTopMeals(top10Meals);
                const averageGlucoseByDay = analyzeTrendsByDayOfWeek(analysisResults);
                setTrendsByDay(averageGlucoseByDay);
                setCsvData(analysisResults);
                setIsCSVReady(true);
                setIsLoading(false);
            };
        }
    };

    if (Object.keys(rawData).length > 0) {
        return (
            <div>
                <Button
                    variant={isCSVReady ? "contained" : "outlined"}
                    color={!isCSVReady ? "warning" : "success"}
                    onClick={handleDownloadClick}
                    disabled={isLoading}
                    startIcon={isLoading ? <CircularProgress variant={"indeterminate"} size={20}/> : null}
                >
                    {isLoading ? "Loading" : isCSVReady ? (
                        <CSVLink
                            data={csvData}
                            headers={headers}
                            filename={filename}
                            style={{color: '#fff', textDecoration: 'none'}}
                        >
                            Download CSV
                        </CSVLink>
                    ) : "Start Analysis"}
                </Button>
                {isCSVReady && <Button sx={{marginLeft: 2}} onClick={() => setShowModal(true)}>Show Analysis</Button>}
                <TopMealsModal
                    user={user}
                    selectedUserId={selectedUserId}
                    isAdmin={isAdmin}
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                    topMeals={topMeals}
                    isLoading={isLoading}
                    csvData={csvData}
                    filename={filename}
                    trendsByDay={trendsByDay}
                    nutrientPercentages={nutrientPercentages}
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
        );
    } else {
        return null;
    }
};

export default CSVDownloadButton;
