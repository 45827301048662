import React, {useState, Suspense, useCallback} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import MealCard, {calculateDailyValue} from './../Components/MealCard';
import DayGlucoseGraph from './DayGlucoseGraph';
import {useServices} from "../providers/service-provider";
import {useFetchUserMealNutritionData} from "../api";
import {BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid} from 'recharts';
import NutritionLabel from "../Components/NutritionLabel";

const DayDataView = ({date, chartData, yAxisMax, high, meals, TIR}) => {
    const {authorizedHttpClient} = useServices();
    const fetchUserMealNutritionData = useFetchUserMealNutritionData(authorizedHttpClient);
    const reverseMeals = [...meals].reverse();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totals, setTotals] = useState({
        fat: 0,
        salt: 0,
        sugar: 0,
        carbs: 0,
        protein: 0,
        kilojoules: 0,
        calories: 0, // Add calories (kCal)
        saturatedFat: 0,
    });
    const [missingInfo, setMissingInfo] = useState([]);

    const calculateTotals = useCallback(async () => {
        let totalFat = 0, totalSalt = 0, totalSugar = 0, totalCarbs = 0, totalProtein = 0, totalKilojoules = 0,
            totalSaturatedFat = 0, totalCalories = 0;
        let missingInfoDishes = [];

        for (let meal of meals) {
            const data = await fetchUserMealNutritionData(meal.id);

            data.forEach(dish => {
                const portionMultiplier = parseFloat(dish.portionSize || 0) / 100;

                totalFat += parseFloat(dish.fatG || 0) * portionMultiplier;
                totalSalt += parseFloat(dish.saltG || 0) * portionMultiplier;
                totalSugar += parseFloat(dish.sugarG || 0) * portionMultiplier;
                totalCarbs += parseFloat(dish.carbohydratesG || 0) * portionMultiplier;
                totalProtein += parseFloat(dish.proteinG || 0) * portionMultiplier;
                totalKilojoules += parseFloat(dish.kilojoules || 0) * portionMultiplier;
                totalSaturatedFat += parseFloat(dish.fatSaturatedG || 0) * portionMultiplier;
                totalCalories += (parseFloat(dish.kilojoules || 0) * portionMultiplier) * 0.239006; // Convert kJ to kCal

                if (!dish.fatG || !dish.saltG || !dish.sugarG || !dish.carbohydratesG || !dish.proteinG || !dish.kilojoules || !dish.fatSaturatedG) {
                    missingInfoDishes.push(dish.dishName);
                }
            });
        }

        setTotals({
            fat: totalFat,
            salt: totalSalt,
            sugar: totalSugar,
            carbs: totalCarbs,
            protein: totalProtein,
            kilojoules: totalKilojoules,
            calories: totalCalories, // Include calculated calories
            saturatedFat: totalSaturatedFat,
        });
        setMissingInfo(missingInfoDishes);
        setIsModalOpen(true);
    }, [fetchUserMealNutritionData, meals]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const data = [
        {name: 'Fat (g)', value: totals.fat},
        {name: 'Salt (g)', value: totals.salt},
        {name: 'Sugar (g)', value: totals.sugar},
        {name: 'Carbs (g)', value: totals.carbs},
        {name: 'Protein (g)', value: totals.protein},
        {name: 'Saturated Fat (g)', value: totals.saturatedFat},
    ];

    const kilojoulesData = totals.kilojoules;

    return (
        <Box sx={{marginTop: 2}}>
            <Box sx={{height: 10}}></Box>
            <Box sx={{backgroundColor: '#FDD247', paddingY: 0.4}}>
                <h3>Date: {dayjs(date).format("ddd, MMM D, YYYY")}</h3>
            </Box>
            <Suspense fallback={<div>Loading Graph...</div>}>
                <DayGlucoseGraph chartData={chartData} yAxisMax={yAxisMax} high={high}/>
            </Suspense>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                paddingBottom: 6,
                paddingTop: 2,
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: 1,
                        overflowX: 'auto',
                        minHeight: 250,
                        width: '95%'
                    }}
                >
                    {reverseMeals.map((meal, index) => (
                        <MealCard key={index} meal={meal} index={index} width={240} height={160}/>
                    ))}
                </Box>
                <Box sx={{padding: 2}}></Box>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: 4}}>
                <Button size={"small"} variant="contained" color="secondary" onClick={calculateTotals}>
                    Calculate Daily Nutrition Totals
                </Button>
            </Box>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="day-total-nutrition-modal"
                aria-describedby="day-total-nutrition-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 700,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Typography id="day-total-nutrition-modal" variant="h6" component="h2">
                        Daily Nutrition Totals
                    </Typography>
                    {missingInfo.length > 0 && (
                        <Alert severity="warning" sx={{mt: 2}}>
                            Some dishes are missing nutritional data, so totals may be inaccurate.
                        </Alert>
                    )}
                    <Box sx={{m: 3}}>
                        {/* Add the NutritionLabel component here */}
                        <NutritionLabel
                            data={{
                                name: 'Daily Nutrition Summary',
                                servingsPerContainer: '1',
                                servingSize: 'Varies',
                                calories: totals.calories.toFixed(2),
                                nutrients: [
                                    {
                                        label: 'Fat',
                                        amount: `${totals.fat.toFixed(2)}g`,
                                        dailyValue: calculateDailyValue(totals.fat, 78)
                                    },
                                    {
                                        label: 'Saturated Fat',
                                        amount: `${totals.saturatedFat.toFixed(2)}g`,
                                        dailyValue: calculateDailyValue(totals.saturatedFat, 20)
                                    },
                                    {
                                        label: 'Carbohydrate',
                                        amount: `${totals.carbs.toFixed(2)}g`,
                                        dailyValue: calculateDailyValue(totals.carbs, 275)
                                    },
                                    {
                                        label: 'Sugars',
                                        amount: `${totals.sugar.toFixed(2)}g`,
                                        dailyValue: calculateDailyValue(totals.sugar, 50)
                                    },
                                    {
                                        label: 'Protein',
                                        amount: `${totals.protein.toFixed(2)}g`,
                                        dailyValue: calculateDailyValue(totals.protein, 50)
                                    },
                                    {
                                        label: 'Salt',
                                        amount: `${totals.salt.toFixed(2)}mg`,
                                        dailyValue: calculateDailyValue(totals.salt, 2300)
                                    },
                                ],
                                micronutrients: [] // Add micronutrient data if available
                            }}
                        />
                    </Box>

                    <Typography id="day-total-nutrition-modal" variant="body1" sx={{m: 1}}>
                        <strong>Kilojoules:</strong> {kilojoulesData.toFixed(2)}
                    </Typography>
                    <Typography id="day-total-nutrition-modal" variant="body1" sx={{m: 1}}>
                        <strong>Calories (kCal):</strong> {totals.calories.toFixed(2)}
                    </Typography>

                    <ResponsiveContainer width="100%" height={300} sx={{mt: 4}}>
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <Tooltip/>
                            <Bar dataKey="value" fill="#8884d8"/>
                        </BarChart>
                    </ResponsiveContainer>
                    {missingInfo.length > 0 && (
                        <Box sx={{mt: 2}}>
                            <Typography variant="body1" color="error">
                                <strong>Dishes with Missing Data:</strong>
                            </Typography>
                            {missingInfo.map((dishName, index) => (
                                <Typography key={index} variant="body2">
                                    {dishName}
                                </Typography>
                            ))}
                        </Box>
                    )}
                    <Button onClick={handleCloseModal} sx={{mt: 2}}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}
export default DayDataView;
