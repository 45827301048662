import {HttpClient} from "./services/http-client";
import {auth} from "./firebase";

//const BASE_URL = 'http://localhost:5002';
const BASE_URL = 'https://api.heymeala.com';

const httpClient = new HttpClient(BASE_URL, () => auth.currentUser.getIdToken());


export const services = {
    authorizedHttpClient: httpClient,
};
