import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const PlacesDropdown = ({ data, onPlaceSelect }) => {
    const [selectedPlace, setSelectedPlace] = useState('');

    const handleChange = (event) => {
        const selectedId = event.target.value;
        setSelectedPlace(selectedId);
        onPlaceSelect(selectedId);
    };

    return (
        <div style={{width:"100%", height:90, backgroundColor:"rgb(248,246,233)"}}>
        <FormControl style={{backgroundColor:"white", width:300, margin:12}} >
            <InputLabel id="places-dropdown-label">Places Time in Range</InputLabel>
            <Select
                labelId="places-dropdown-label"
                id="places-dropdown"
                value={selectedPlace}
                label="Places"
                onChange={handleChange}
            >
                {data.map((place) => (
                    <MenuItem key={place.id} value={place.id}>
                        {place.avgTIR}% TiR – {place.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        </div>
    );
};
