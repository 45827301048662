import React from "react";
import { tirToColor } from "../utils/tirColor";

const MyMarker = ({ text, tooltip, $hover, avgTIR, isSelected }) => {
    const handleClick = () => {
        console.log(`You clicked on ${tooltip}`);
    };

    const backgroundColor = avgTIR ? tirToColor(avgTIR) : 'gray';
    // This combines both hover and selected logic into one string
    const markerClass = `${$hover ? "circle hover" : "circle"} ${isSelected ? "selected" : ""}`;

    return (
        <div className={markerClass} onClick={handleClick} title={tooltip} style={{backgroundColor}}>
            <span className="circleText"></span>
            <div className="tooltip">{text} - TIR: {avgTIR}%</div>
        </div>
    );
};


export default MyMarker;
