import React from "react";
import {Box, Button, Checkbox, FormControlLabel} from "@mui/material";
import BasicDatePicker from "./Charts/DatePickerModule";
import YAxisSelect from "./Charts/YAxisSelect";
import UserSelectDropdown from "./Charts/UserSelectDropdown";
import {userIDS} from "./user";
import CSVDownloadButton from "./CSVDownloadButton";

const CustomMenu = ({
                        data,
                        loadAllDays,
                        setLoadAllDays,
                        startDate,
                        setStartDate,
                        endDate,
                        setEndDate,
                        setYAxisMax,
                        isAdmin,
                        selectedUserName,
                        handleUserSelect,
                        user,
                        selectedUserId,
                        handleToggleView,
                        showListView,
                        setShowMapOverlay,
                        showMapOverlay
                    }) => {

    const handleChange = (event) => {
        console.log(event.target.checked)
        setLoadAllDays(event.target.checked);
    };

    return (
        <>
            <Box sx={{
                height: 120,
                backgroundColor: '#fff',
                position: "sticky",
                top: 0,
                zIndex: 999,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <BasicDatePicker label="Start Date" value={startDate} setValue={setStartDate}/>
                    <BasicDatePicker label="End Date" value={endDate} setValue={setEndDate}/>
                    <YAxisSelect setYAxisMax={setYAxisMax}/>
                    {isAdmin && (
                        <UserSelectDropdown
                            userIDS={userIDS}
                            selectedUserName={selectedUserName}
                            onUserSelect={handleUserSelect}
                            user={user}
                        />
                    )}
                    <Box sx={{margin: 2}}>
                        <Button variant="contained" onClick={handleToggleView}>
                            {showListView ? "Show List View" : "Show Graph View"}
                        </Button>
                    </Box>
                    <Box sx={{margin: 2}}>
                        <Button variant="contained" onClick={() => setShowMapOverlay(!showMapOverlay)}>
                            {showListView ? "Show Map" : "Hide Map"}
                        </Button>
                    </Box>
                    <div>
                        <CSVDownloadButton
                            rawData={data}
                            startDate={startDate}
                            endDate={endDate}
                            filename="GlucoseAnalysis.csv"
                            user={user}
                            selectedUserId={selectedUserId}
                            isAdmin={isAdmin}
                        />
                    </div>
                    {showListView &&
                        <FormControlLabel
                            sx={{marginLeft: 2}}
                            control={
                                <Checkbox
                                    checked={loadAllDays}
                                    onChange={handleChange}
                                    inputProps={{'aria-label': 'controlled'}}
                                />}
                            label="Timeline"
                        />
                    }

                </Box>
            </Box>
        </>
    )
}
export default CustomMenu
